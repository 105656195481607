import React from "react"
import styled from 'styled-components'
import sharedStyles from "../shared/styles.js"
import logoCivic from "./assets/clients/civic.svg"
import logoMysteriumNetwork from "./assets/clients/mysterium-network.png"
import logoParsiq from "./assets/clients/parsiq.png"

import logoFalconix from "./assets/clients/falconix.svg"
import logoFilesFm from "./assets/clients/files-fm.svg"
import logoNoia from "./assets/clients/noia.svg"
import logoOrca from "./assets/clients/orca.svg"

const ClientsSection = styled.div`
  background: ${sharedStyles.sectionBackgroundColor};
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  position: relative;
  padding-bottom: 1.2rem;
  @media (max-width: 480px) {
    padding-bottom: 0;
  }
`
const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 2.2rem 0 1.2rem;
  justify-content: center;

  @media (max-width: 1100px) {
    justify-content: center;
  }

  @media (max-width: 480px) {
    padding: 0;
  }
`
const ListItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1rem 0;
  padding: 0 1rem;
  flex-basis: 14.28%;
  flex-grow: 0;
  box-sizing: border-box;
  text-decoration: none;

  @media (max-width: 1100px) {
    flex-basis: 33.3%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -1.3rem;
    height: 2.6rem;
    border-left: 1px solid ${sharedStyles.borderColor};
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    &::before {
      display: none;
    }
  }
`
const Logo = styled.img`
  max-width: 100%;
`
const Title = styled.div`
  font-size: .67rem;
  text-align: center;
  letter-spacing: .08rem;
  text-transform: uppercase;
  color: ${sharedStyles.clientsTitleColor};

  @media (max-width: 480px) {
    display: none;
  }
`

const Clients = () => (
  <ClientsSection id="clients">
    <PageWrap>
      <List>
        <ListItem data-aos="fade-up" data-aos-duration="300" data-aos-delay="100" target="_blank" href="https://www.parsiq.net/"><Logo src={logoParsiq} style={{ maxHeight: '31px' }} alt="parsiq"></Logo></ListItem>
        {/*<ListItem data-aos="fade-up" data-aos-delay="200" target="_blank" href="https://falconix.com/"><Logo src={logoFalconix} style={{ maxHeight: '24px' }} alt="Falconix"></Logo></ListItem>*/}
        <ListItem data-aos="fade-up" data-aos-duration="300" data-aos-delay="200" target="_blank" href="https://files.fm/"><Logo src={logoFilesFm} style={{ maxHeight: '24px' }} alt="files.fm"></Logo></ListItem>
        <ListItem data-aos="fade-up" data-aos-duration="300" data-aos-delay="300" target="_blank" href="https://mysterium.network/"><Logo src={logoMysteriumNetwork} style={{ maxHeight: '72px' }} alt="mysterium network"></Logo></ListItem>
        <ListItem data-aos="fade-up" data-aos-duration="300" data-aos-delay="400" target="_blank" href="https://www.civic.com/"><Logo src={logoCivic} style={{ maxHeight: '42px' }} alt="civic"></Logo></ListItem>
        <ListItem data-aos="fade-up" data-aos-duration="300" data-aos-delay="500" target="_blank" href="https://noia.network/"><Logo src={logoNoia} style={{ maxHeight: '36px' }} alt="NOIA"></Logo></ListItem>
        {/*<ListItem data-aos="fade-up" data-aos-delay="700" target="_blank" href="https://orcaalliance.eu/"><Logo src={logoOrca} style={{ maxHeight: '34px' }} alt="ORCA"></Logo></ListItem>*/}
      </List>
      <Title>JOIN THE GROUP OF PROFESSIONALS that ALREADY TRUST US</Title>
    </PageWrap>
  </ClientsSection>
)

export default Clients
