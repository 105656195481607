import { css } from 'styled-components'

export const sharedStyles = {
  darkestColor: '#000',
  darkestHoverColor: '#585858',
  lightestColor: '#fff',
  lightestHoverColor: '#b7e3fa',
  headingColor: '#c9d1d5',
  hoverColor: '#0089d0',
  eventTextColor: '#bab8b2',
  eventTextHoverColor: '#fefefe',
  eventTitleColor: '#b59812',
  eventTitleHoverColor: '#d0b01b',
  eventDateColor: '#b2a985',
  activitiesTextColor: '#70787c',
  clientsTitleColor: '#818487',
  heroTextColor: '#e4f7f3',
  servicesTextColor: '#6a6a6a',
  textColor: '#525252',
  footerNavColor: '#d2d2d2',
  footerNavHoverColor: '#a5a5a5',
  footerColor: '#616161',
  footerHoverColor: '#7a692c',
  mainQuoteColor: '#5e6467',
  mainQuoteLinkColor: '#0093c5',
  mainQuoteLinkHoverColor: '#00bf70',

  borderColor: '#ddd',

  darkBackgroundColor: '#282828',
  sectionBackgroundColor: '#fafbfb',
  eventDateBackgroundColor: '#423c22',
  contactSpacerBackgroundColor: '#e1e1e1',
  activitiesSpacerBackgroundColor: '#ececec',
  activitiesSocialBackgroundColor: '#f0f2f2',
  languagesBackgroundColor: '#f1f3f3',
  languagesArrowBackgroundColor: '#0a0a0a',

  pageWrap: css`
    margin: 0 auto;
    padding-right: 1.0875rem;
    padding-left: 1.0875rem;
    max-width: 72.2rem;
  `,
  h1: css`
    line-height: 1.2;
    letter-spacing: -.1rem;
    font-size: 2.6rem;
    font-weight: 300;
  `,
  h2: css`
    line-height: 1.2;
    letter-spacing: -.04rem;
    font-size: 2.334rem;
    font-weight: 300;
  `,
  h3: css`
    line-height: 1.2;
    letter-spacing: -.06rem;
    font-size: 1.7rem;
    font-weight: 300;

    @media (max-width: 1110px) {
      font-size: 1.2rem;
    }
  `,
  commonText: css`
    letter-spacing: .03rem;
    font-size: .723rem;
  `,
}

export default sharedStyles;