import React from "react"
import { withPrefix } from 'gatsby'
import styled, { css } from 'styled-components'
import sharedStyles from "../shared/styles"
import img1 from './assets/activities/img/Owning_Digital_Money.jpg'
import img2 from './assets/activities/img/img2.jpg'
import img3 from './assets/activities/img/Deloitte_Latvia_Blockvis.jpg'
import IconFilePdf from './assets/activities/iconFilePdf.inline.svg'
import IconFileVideo from './assets/activities/iconFileVideo.inline.svg'
import IconSocialFacebook from './assets/activities/social/facebook.inline.svg'
import IconSocialTwitter from './assets/activities/social/twitter.inline.svg'
import IconSocialTelegram from './assets/activities/social/telegram.inline.svg'

const ActivitiesSection = styled.div`
  background: ${sharedStyles.sectionBackgroundColor};
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  padding-top: 4.1rem;
  padding-bottom: 2.2rem;
  @media (max-width: 480px) {
    padding-top: 2.1rem;
    padding-bottom: 0;
  }
`
const Wrap = styled.div`
  display: flex;
  @media (max-width: 920px) {
    display: block;
  }
  @media (max-width: 480px) {
    position: relative;
  }
`
const Main = styled.div`
  flex-grow: 1;
`
const Sidebar = styled.div`
  flex-basis: 16.7rem;
  flex-shrink: 0;
`
const Heading = styled.div`
  ${sharedStyles.h1}
  color: ${sharedStyles.headingColor};
`
const Grid = styled.div`
  margin: 2.8rem 0 2.5rem;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    margin-top: 1.5rem;
  }
`
const Item = styled.a.attrs(() => ({
  target: "_blank",
}))`
  display: flex;
  flex-direction: column;
  margin-right: 6.2%;
  width: 27.1%;
  line-height: 1.2;
  text-decoration: none;

  @media (max-width: 620px) {
    flex-direction: row-reverse;
    margin-right: 0;
    width: 100%;
  }
`
const TextWrap = styled.div`
  @media (max-width: 620px) {
    flex-grow: 1;
  }
`
const Date = styled.div`
  ${sharedStyles.commonText}
  margin-bottom: .7rem;
  color: ${sharedStyles.hoverColor};
`
const Spacer = styled.div`
  margin-bottom: 1.1rem;
  width: 4rem;
  height: .3rem;
  background: ${sharedStyles.activitiesSpacerBackgroundColor};

  @media (max-width: 620px) {
    margin-bottom: .5rem;
  }
`
const Title = styled.div`
  margin-bottom: .18rem;
  font-weight: bold;
  letter-spacing: .02rem;
  color: ${sharedStyles.darkestColor};

  ${Item}:hover & {
    color: ${sharedStyles.darkestHoverColor};
  }
`
const Text = styled.div`
  ${sharedStyles.commonText}
  margin-bottom: 1.5rem;
  line-height: 1.29;
  color: ${sharedStyles.activitiesTextColor};

  ${Item}:hover & {
    color: ${sharedStyles.darkestHoverColor};
  }
`
const ImageContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  flex-grow: 1;

  @media (max-width: 620px) {
    margin-top: 0;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 1rem;
    width: 6.7rem;
  }
`
const Image = styled.img`
  max-width: 100%;
`
const FileList = styled.div`
  margin-bottom: 3.1rem;
  margin-top: .4rem;
  padding: 0 1.5rem 0 2.7rem;
  border-left: 1px solid ${sharedStyles.borderColor};

  @media (max-width: 620px) {
    padding-left: 1rem;
  }
  @media (max-width: 480px) {
    display: none;
  }
`
const FileLink = styled.a.attrs(() => ({
  target: "_blank",
}))`
  display: flex;
  margin-top: 1.4rem;

  &:first-child {
    margin-top: 0;
  }

  &:hover path {
    fill: ${sharedStyles.hoverColor};
  }
`
const fileLinkIconStyles = css`
  flex-shrink: 0;
  margin-right: .9rem;
  height: 2.6rem;
  width: 2.2rem;
`
const FileLinkIconPdf = styled(props => <IconFilePdf {...props}><use xlinkHref="#svg-pdf-file"></use></IconFilePdf>)`
  ${fileLinkIconStyles};
`
const FileLinkIconVideo = styled(props => <IconFileVideo {...props}><use xlinkHref="#svg-video-file"></use></IconFileVideo>)`
  ${fileLinkIconStyles};
`
const FileLinkText = styled.div`
  ${sharedStyles.commonText};
  margin-top: .8rem;
  line-height: 1.2;
  font-weight: bold;
  color: ${sharedStyles.darkestColor};
`
const SocialBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem 0 1.6rem 2.7rem;
  background: ${sharedStyles.activitiesSocialBackgroundColor};
  box-shadow: 16rem 0 0 0 ${sharedStyles.activitiesSocialBackgroundColor};

  @media (max-width: 480px) {
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    box-shadow: none;
    padding: 1rem 0;
  }
`
const SocialTitle = styled.div`
  margin-right: .8rem;
  line-height: 1.2;
  font-weight: bold;
  letter-spacing: .04rem;
  text-transform: uppercase;
  white-space: nowrap;
  @media (max-width: 480px) {
    display: none;
  }
`
const SocialLink = styled.a.attrs(() => ({
  target: "_blank",
}))`
  display: flex;
  margin-left: 0.7rem;
  width: 1.5rem;
  height: 1.5rem;
`
const socialIconStyles = css`
  max-height: 100%;

  &:hover path {
    fill: ${sharedStyles.hoverColor};
  }
`
const IconFacebook = styled(props => <IconSocialFacebook {...props}><use xlinkHref="#svg-facebook"></use></IconSocialFacebook>)`
  ${socialIconStyles};
`
const IconTwitter = styled(props => <IconSocialTwitter {...props}><use xlinkHref="#svg-twitter"></use></IconSocialTwitter>)`
  ${socialIconStyles};
`
const IconTelegram = styled(props => <IconSocialTelegram {...props}><use xlinkHref="#svg-telegram"></use></IconSocialTelegram>)`
  ${socialIconStyles};
`

const Activities = () => (
  <ActivitiesSection id="activities">
    <PageWrap>
      <Wrap>
        <Main>
          <Heading>Activities</Heading>
          <Grid>
            <Item href="https://www.eventbrite.com/e/digecon-live-owning-digital-money-for-beginners-tickets-105773226568">
              <TextWrap>
                <Date>19.05.20</Date>
                <Spacer />
                <Title>Owning Digital Money</Title>
                <Text>Owning Digital Money from the perspective of Math, Crypto and Smart Contracts</Text>
              </TextWrap>
              <ImageContainer>
                <Image src={img1} alt="Imagine. Explore. Achieve. IIA Latvia Konference 2019"/>
              </ImageContainer>
            </Item>
            <Item href="https://www.vid.gov.lv/lv/blokkezu-eksperti-hakatona-izstrada-risinajumus-nodoklu-krapniecibas-noversanai-0">
              <TextWrap>
                <Date>23.05.19</Date>
                <Spacer />
                <Title>.tax Blockchain Hackathon</Title>
                <Text>First blockchain hackathon in Latvia to create new public service solutions.</Text>
              </TextWrap>
              <ImageContainer>
                <Image src={img2} alt="Tax Blockchain Hackathon"/>
              </ImageContainer>
            </Item>
            <Item href="https://www2.deloitte.com/lv/en/pages/about-deloitte/articles/collaboration-with-startups.html">
              <TextWrap>
                <Date>15.05.18</Date>
                <Spacer />
                <Title>Why Software is Eating the World</Title>
                <Text>Deloitte Latvia has started cooperating with three technology companies - Graphdo, Notakey and Blockvis.</Text>
              </TextWrap>
              <ImageContainer>
                <Image src={img3} alt="Deloitte Latvia has started cooperating with Blockvis"/>
              </ImageContainer>
            </Item>
          </Grid>
        </Main>
        <Sidebar>
          <FileList>
            <FileLink href={withPrefix('/presentations/MassiveInvariants.pdf')}>
              <FileLinkIconPdf />
              <FileLinkText>The Power of Massive Invariant Environments</FileLinkText>
            </FileLink>
            <FileLink href={withPrefix('/presentations/PARSIQ_Presentation_18062019_003.pdf')}>
              <FileLinkIconPdf />
              <FileLinkText>Blockchain monitoring with smart triggers</FileLinkText>
            </FileLink>
            <FileLink href={withPrefix('/presentations/IAI_2019.pdf')}>
              <FileLinkIconPdf />
              <FileLinkText>Blockchain Core Concepts for auditors</FileLinkText>
            </FileLink>
            <FileLink href={withPrefix('/presentations/MisBlockchain.pdf')}>
              <FileLinkIconPdf />
              <FileLinkText>Common misunderstandings & demythologization of blockchain</FileLinkText>
            </FileLink>
            <FileLink href={withPrefix('/presentations/NotSoFungible.pdf')}>
              <FileLinkIconPdf />
              <FileLinkText>NotSoFungible Crypto</FileLinkText>
            </FileLink>
          </FileList>
          <SocialBlock>
            <SocialTitle>Follow us on</SocialTitle>
            <SocialLink data-aos="fade-up" data-aos-delay="100" href="https://www.facebook.com/blockvis/" aria-label="Blockvis on Facebook">
              <IconFacebook />
            </SocialLink>
            <SocialLink data-aos="fade-up" data-aos-delay="200" href="https://twitter.com/blockvis_team" aria-label="Blockvis on Twitter">
              <IconTwitter />
            </SocialLink>
            {/*<SocialLink data-aos="fade-up" data-aos-delay="300" href="#" aria-label="Link to Telegram">*/}
            {/*  <IconTelegram />*/}
            {/*</SocialLink>*/}
          </SocialBlock>
        </Sidebar>
      </Wrap>
    </PageWrap>
  </ActivitiesSection>
)
export default Activities
