import React from "react"
import styled from 'styled-components'
import sharedStyles from "../shared/styles"
import iconDevelopment from './assets/services/development.svg'
import iconAudit from './assets/services/audit.svg'
import iconConsulting from './assets/services/consulting.svg'

const ServicesSection = styled.div`
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  padding-top: 4.8rem;
  padding-bottom: 3.5rem;
  @media (max-width: 880px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`
const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const Item = styled.div`
  width: 30%;
  margin: 1rem 0;
  @media (max-width: 880px) {
    width: 100%;
  }
`
const IconContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2.7rem;
  height: 5.6rem;
  @media (max-width: 880px) {
    margin-bottom: 1rem;
  }
`
const Icon = styled.img`
`
const IconDevelopment = styled(Icon)`
  max-height: 86%;
`
const IconAudit = styled(Icon)`
  max-height: 95%;
`
const IconConsulting = styled(Icon)`
  max-height: 100%;
`
const Title = styled.div`
  ${sharedStyles.h2}
  margin-bottom: .9rem;
`
const Text = styled.div`
  line-height: 1.6;
  color: ${sharedStyles.servicesTextColor};
`

const Services = () => (
  <ServicesSection id="services">
    <PageWrap>
      <Grid>
        <Item data-aos="fade-up" data-aos-delay="100">
          <IconContainer>
            <IconDevelopment src={iconDevelopment} alt="Development"/>
          </IconContainer>
          <Title>Development</Title>
          <Text>We help our clients to enhance and facilitate existing business processes by leveraging the blockchain technology or its parts. Sometimes immutability, transparency and auditability could be implemented using more lightweight techniques than the blockchain. We are ready to identify these cases and use appropriate technology/cryptography.</Text>
        </Item>
        <Item data-aos="fade-up" data-aos-delay="200">
          <IconContainer>
            <IconAudit src={iconAudit} alt="Audit"/>
          </IconContainer>
          <Title>Audit</Title>
          <Text>We provide traditional audit for Ethereum based smart-contracts. As well we provide another type of audit: checking mathematical correctness of proofs used in custom blockchain/cryptographic protocols and constructing attacks to these protocols. </Text>
        </Item>
        <Item data-aos="fade-up" data-aos-delay="300">
          <IconContainer>
            <IconConsulting src={iconConsulting} alt="Consulting"/>
          </IconContainer>
          <Title>Consulting</Title>
          <Text>Through the extensive communication we help our clients to identify right parts of their business scope that could benefit from blockchain technology. Deep knowledge of technology capabilities allows us to reveal unnoticed opportunities for client business.</Text>
        </Item>
      </Grid>
    </PageWrap>
  </ServicesSection>
)
export default Services
