import React from "react"
import styled from 'styled-components'
import sharedStyles from "../shared/styles"
import imgEvent from "./assets/event/event.jpg"

const EventSection = styled.div`
  background: ${sharedStyles.darkBackgroundColor};
  background: linear-gradient(135deg, rgba(42,42,42,1) 0%, rgba(26,26,26,1) 54%, rgba(26,26,26,1) 100%);
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  position: relative;
  display: flex;
  padding-top: 3.8rem;
  padding-bottom: 3.3rem;
  @media (max-width: 1110px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`
const ImgContainer = styled.div`
  margin-right: 2.7rem;
  @media (max-width: 1110px) {
    width: 33.3%;
    margin-right: 1.2rem;
    flex-shrink: 0;
  }
`
const Img = styled.img`
  width: 100%;
`
const TextWrap = styled.a`
  max-width: 34rem;
  color: ${sharedStyles.eventTextColor};

  &:hover {
    color: ${sharedStyles.eventTextHoverColor};
  }

  @media (max-width: 1110px) {
    flex-grow: 1;
    max-width: none;
  }
`
const Title = styled.div`
  ${sharedStyles.h2}
  margin: .35rem 0 .6rem;
  display: inline-block;
  text-decoration: none;
  color: ${sharedStyles.eventTitleColor};

  ${TextWrap}:hover & {
    color: ${sharedStyles.eventTitleHoverColor};
  }

  @media (max-width: 768px) {
    margin-bottom: 0;
    padding: .3rem 1.2rem 0 0;
    line-height: 1.4;
    font-size: 1.2rem;
  }
`
const Subtitle = styled.div`
  font-weight: bold;
  @media (max-width: 850px) {
    display: none;
  }
`
const Text = styled.div`
  line-height: 1;
  @media (max-width: 850px) {
    display: none;
  }
`
const Date = styled.div`
  ${sharedStyles.h3}
  position: absolute;
  top: 0;
  right: 1.12rem;
  padding: 4.2rem 1.7rem 2.4rem 1.8rem;
  width: 13.612rem;
  background: ${sharedStyles.eventDateBackgroundColor};
  box-sizing: border-box;
  color: ${sharedStyles.eventDateColor};

  @media (max-width: 1110px) {
    position: static;
    width: auto;
    margin-left: -1.2rem;
    padding: .5rem 1.2rem;

    & > br {
      display: none;
    }
  }
`

const Event = () => (
  <EventSection>
    <PageWrap>
      <ImgContainer>
        <Img src={imgEvent} alt="Event"></Img>
      </ImgContainer>
      <TextWrap href="#">
        <Date>
          10-12 April&nbsp;
          <br/>
          2020
        </Date>
        <Title>Blockvis + Hodl Hodl presents CryptoGeCon 2020</Title>
        <Subtitle>Bitcoin, zero-knowledge cryptography, time.</Subtitle>
        <Text>Georgia's Innovation and Technology Agency, Tbilisi, Georgia</Text>
      </TextWrap>
    </PageWrap>
  </EventSection>
)

export default Event
