import React from "react"
import styled from 'styled-components'
import sharedStyles from "../shared/styles"
import bgImg from './assets/promo.jpg'

const PromoSection = styled.div`
  background: #009c9d url(${bgImg}) no-repeat 0 0;
  background-size: cover;
  color: ${sharedStyles.lightestColor};
  @media (max-width: 480px) {
    display: none;
  }
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 19.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 580px) {
    height: auto;
  }
`
const Text = styled.div`
  ${sharedStyles.h2}

  @media (max-width: 920px) {
    font-size: 1.389rem;
  }
`

const Promo = () => (
  <PromoSection>
    <PageWrap>
      <Text>
        Sometimes trust can not be replaced with&nbsp;verification. <br/>
        Sometimes it can. Let’s decide together.<br />
        <small style={{
          fontSize: '50%',
          fontStyle: 'italic'
        }}>&mdash; Inspired by Gödel's theorem</small>
      </Text>
    </PageWrap>
  </PromoSection>
)
export default Promo
