import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import styled, { css } from "styled-components"
import sharedStyles from "../shared/styles"
import iconQuoteLeft from "./assets/quotes/icon-quote-left.svg"
import iconQuoteRight from "./assets/quotes/icon-quote-right.svg"
import pic1 from "./assets/quotes/pics/Daniel Kelleher.jpeg"
import pic2 from "./assets/quotes/pics/Andre Kalinowski.jpeg"
import pic3 from "./assets/quotes/pics/Janis Viklis.jpeg"
import pic4 from "./assets/quotes/pics/Jonas Simanavicius.jpeg"

const TestimonialsSection = styled.div`
  padding: 5.2rem 0 4.6rem;
  color: ${sharedStyles.textColor};

  @media (max-width: 480px) {
    position: relative;
    padding: 3rem 0 1rem;

    ::after {
      content: '';
      position: absolute;
      right: 1.0875rem;
      bottom: 0;
      left: 1.0875rem;
      height: 1px;
      background: #ddd;
    }
  }
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  position: relative;
  padding-bottom: 1.2rem;
  text-align: center;
`
const MainQuote = styled.div`
  margin: 0 auto;
  max-width: 61rem;
`
const quotesStyles = css`
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 2.1rem;
    height: 2.1rem;
    background-size: contain;
    background-position: 0 0;
    background-repeat: no-repeat;
    @media (max-width: 920px) {
      width: 1.1rem;
      height: 1.1rem;
    }
  }
  &::before {
    top: .9rem;
    left: 0;
    background-image: url(${iconQuoteLeft});
  }
  &::after {
    right: 0;
    bottom: 0;
    background-image: url(${iconQuoteRight});
  }
`
const MainQuoteText = styled.div`
  ${sharedStyles.h2}
  position: relative;
  padding: 0 5rem;
  text-align: center;
  color: ${sharedStyles.mainQuoteColor};

  ${quotesStyles};

  @media (max-width: 920px) {
    font-size: 1.389rem;
  }
  @media (max-width: 480px) {
    padding: 0 20px;
    font-size: 19px;
    letter-spacing: 0;
  }
`
const MainQuoteAuthor = styled.div`
  margin: 0 auto;
  padding: 2.7rem 0 6.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  @media (max-width: 920px) {
    padding: 1.5rem 0;
  }
  @media (max-width: 480px) {
    padding: 1rem 0 .5rem;
  }
`
const MainQuoteAuthorPicture = styled.img`
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;

  @media (max-width: 920px) {
    width: 3.5rem;
    height: 3.5rem;
  }
  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`
const MainQuoteAuthorData = styled.div`
  margin-bottom: .7rem;
  padding-left: 1.2rem;
  line-height: 1.4;
  color: ${sharedStyles.darkestColor};
  @media (max-width: 480px) {
    margin-bottom: 0;
    padding-left: 10px;
    font-size: 10px;
  }
`
const MainQuoteAuthorName = styled.div`
  font-weight: bold;
`
const MainQuoteAuthorInfo = styled.div`
`
const MainQuoteLink = styled.a.attrs({ "target": "_blank" })`
  color: ${sharedStyles.mainQuoteLinkColor};

  &:hover {
    color: ${sharedStyles.mainQuoteLinkHoverColor};
  }
`
const List = styled.div`
  display: flex;

  @media (max-width: 920px) {
    display: none;
  }
`
const ListItem = styled.div`
  position: relative;
  flex-grow: 0;
  padding: 0 2rem;
  width: 25%;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-right: 1px solid ${sharedStyles.borderColor};
  }

  &:first-child {
    padding-left: 0;
    &::before {
      display: none;
    }
  }

  &:last-child {
    padding-right: 0;
  }
`
const Quote = styled.div`
`
const QuoteText = styled.div`
  position: relative;
  letter-spacing: .04rem;
  line-height: 1.5;
  padding: 1.51rem 0;
  text-align: left;

  ${quotesStyles}

  &::before,
  &::after {
    width: .7rem;
    height: .7rem;
  }
  &::before {
    top: 0;
  }
  &::after {
    right: auto;
    left: 0;
  }
`
const QuoteAuthor = styled.div`
  margin: .2rem 0 1.3rem;
  display: flex;
  align-items: center;
`
const QuoteAuthorPicture = styled.img`
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
`
const QuoteAuthorData = styled.div`
  ${sharedStyles.commonText}
  margin-bottom: .3rem;
  padding-left: .7rem;
  line-height: 1.4;
  text-align: left;
  color: ${sharedStyles.darkestColor};
`
const QuoteAuthorName = styled(MainQuoteAuthorName)`
`
const QuoteAuthorInfo = styled.div`
`
const QuoteLink = styled(MainQuoteLink)`
`

const testimonials = [
  {
    text: "I worked with a team from Blockvis for twelve months. Blockvis engineers are professional, dedicated and rich in blockchain domain knowledge. It was a pleasure to work with them!",
    pic: pic1,
    author: "Daniel Kelleher",
    link: "https://www.civic.com/",
    company: "Civic Technologies",
    title: "Vice President of Engineering"
  },
  {
    text: "The most competent workforce provider in blockchain. Blockvis successfuly combines enormous theoretical knowledge, deep understanding and philosphy with fast-paced, professional execution",
    pic: pic2,
    author: "Andre Kalinowski",
    link: "https://www.parsiq.net/",
    company: "Parsiq",
    title: "Founder"
  },
  {
    text: "The Blockvis team are definitely go-to experts in fullstack IT systems development, algorithms, data structures, blockchain and other complex solutions! Highly recommend.",
    pic: pic3,
    author: "Janis Viklis",
    link: "https://files.fm/",
    company: "FilesFM",
    title: "CEO"
  },
  {
    text: "Blockvis engineers are competent and dedicated. We were working with Blockvis from very beginning, and found them rock-solid on their solutions and implementations",
    pic: pic4,
    author: "Jonas Simanavicius",
    link: "https://noia.network/",
    company: "NOIA",
    title: "CTO"
  }
]

const Testimonials = () => {
  const settings = {
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          dots: true,
        }
      },
    ]
  }

  const [m, setM] = useState(null);

  useEffect(() => {
    setM(Math.floor(Math.random() * testimonials.length));
  }, []);

  const main = testimonials[m];

  return (
  <TestimonialsSection id="testimonials">
    <PageWrap>
      <Slider {...settings}>
        {m !== null && <MainQuote>
          <MainQuoteText>{main.text}</MainQuoteText>
          <MainQuoteAuthor>
            <MainQuoteAuthorPicture src={main.pic} alt={main.author} />
            <MainQuoteAuthorData>
              <MainQuoteAuthorName>{main.author},</MainQuoteAuthorName>
              <MainQuoteAuthorInfo><MainQuoteLink href={main.link}>{main.company}</MainQuoteLink>, {main.title}</MainQuoteAuthorInfo>
            </MainQuoteAuthorData>
          </MainQuoteAuthor>
        </MainQuote>}
      </Slider>

      <List>
        {testimonials.map((t, i) => <ListItem>
          <Quote data-aos="fade-up" data-aos-delay={(i + 1) * 100}>
            <QuoteAuthor>
              <QuoteAuthorPicture src={t.pic} alt={t.author} />
              <QuoteAuthorData>
                <QuoteAuthorName>{t.author},</QuoteAuthorName>
                <QuoteAuthorInfo><QuoteLink href={t.link}>{t.company}</QuoteLink>, {t.title}</QuoteAuthorInfo>
              </QuoteAuthorData>
            </QuoteAuthor>
            <QuoteText>{t.text}</QuoteText>
          </Quote>
        </ListItem>)}
      </List>
    </PageWrap>
  </TestimonialsSection>
  )
}

export default Testimonials
