import React from "react"
import styled from 'styled-components'
import sharedStyles from "../shared/styles"
import iconWaze from "./assets/icon-waze.svg"

const ContactSection = styled.div`
  position: relative;
  overflow: hidden;
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
`
const Group = styled.div`
  display: flex;
`
const Sidebar = styled.div`
  padding: 2.7rem 0;
  @media (max-width: 680px) {
    padding: 1.8rem 0;
  }
`
const Map = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: calc(50% - 17.5rem);
  @media (max-width: 1100px) {
    left: 38%;
  }
  @media (max-width: 800px) {
    left: 50%;
  }
  @media (max-width: 680px) {
    position: static;
    height: 440px;
  }
`
const Iframe = styled.iframe`
`
const Heading = styled.div`
  ${sharedStyles.h1}
  margin-bottom: 1.8rem;
  color: ${sharedStyles.headingColor};
  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
`
const Spacer = styled.div`
  margin-bottom: 1.9rem;
  width: 1.9rem;
  height: .3rem;
  background: ${sharedStyles.contactSpacerBackgroundColor};
  @media (max-width: 480px) {
    display: none;
  }
`
const Title = styled.div`
  line-height: 1.5;
  font-weight: bold;
`
const Text = styled.div`
  margin-bottom: 1.4rem;
  line-height: 1.6;
`
const Link = styled.a`
  &:hover {
    color: ${sharedStyles.hoverColor};
  }
`
const NavigationLink = styled.a`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-left: 10px;
`
const NavigationIcon = styled.img`
  max-width: 100%;
`

const Contact = () => (
  <ContactSection id="contacts">
    <PageWrap>
      <Group>
        <Sidebar>
          <Heading>Contact us</Heading>
          <Spacer />
          <Title>Blockvis, SIA</Title>
          <Text>
            LV40203070356<br/>
            Maskavas street 240<br/>
            Left tower, 6th floor<br/>
            Riga, LV-1063
            <NavigationLink target="_blank" rel="noreferrer" href="https://www.waze.com/ul?ll=56.9204896%2C24.1701728&navigate=yes">
              <NavigationIcon src={iconWaze} alt="Waze" />
            </NavigationLink>
            <br/>
          </Text>

          <Text>
            +37126124729<br/>
            <Link href="mailto:info@blockvis.com" aria-label="info@blockvis.com">info@blockvis.com</Link>
          </Text>
        </Sidebar>

      </Group>
    </PageWrap>
    <Map>
      <Iframe
        src="https://maps.google.com/maps?q=Blockvis SIA&t=&z=14&ie=UTF8&iwloc=&output=embed"
        title="Map"
        width="100%" height="100%"
      ></Iframe>
    </Map>
  </ContactSection>
)
export default Contact
