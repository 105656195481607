/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import AOS from 'aos';
import "../shared/slick.css"

import "aos/dist/aos.css"
import "./layout.css"
import Header from "./header"
import Hero from "./hero"
import Event from "./event"
import Clients from "./clients"
import Testimonials from "./testimonials"
import Promo from "./promo"
import Services from "./services"
import Activities from "./activities"
import Hiring from "./hiring"
import Contact from "./contact"
import Footer from "./footer"
import SEO from "./seo";

const Layout = ({ children }) => {
  useEffect(() => {
    const aos = AOS;
    aos.init({
      duration: 400,
      offset: 60
    });
  });
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          isEventsBlockVisible
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Blockchain Development and Consulting" />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Hero></Hero>
      {data.site.siteMetadata.isEventsBlockVisible === true ? <Event></Event> : null}
      <Clients></Clients>
      <Testimonials></Testimonials>
      <Promo></Promo>
      <Services></Services>
      <Activities></Activities>
      <Hiring></Hiring>
      <Contact></Contact>
      <Footer></Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
