import React from "react"
import styled from 'styled-components'
import sharedStyles from "../shared/styles"
import bgImg from "./assets/hero/bg.jpg"
import iconHosting from "./assets/hero/icon-hosting.svg"
import iconIntegrations from "./assets/hero/icon-integrations.svg"
import iconWallets from "./assets/hero/icon-wallets.svg"

import imgHosting from "./assets/hero/img-hosting.jpg"
import imgIntegrations from "./assets/hero/img-integrations.jpg"
import imgWallets from "./assets/hero/img-wallets.jpg"

const HeroBG = styled.div`
  min-height: 27rem;
  background: rgb(0,191,113);
  background: linear-gradient(90deg, rgba(0,191,113,1) 0%, rgba(0,137,209,1) 100%);
`
const HeroSection = styled.div`
  min-height: 27rem;
  background: url(${bgImg}) no-repeat 0 0;
  background-size: cover;
  color: ${sharedStyles.lightestColor};
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  display: flex;
  align-items: flex-end;
  min-height: 27rem;
`
const Footer = styled.div`
  display: flex;
  padding-top: 2rem;
  width: 100%;
  min-height: 13.612rem;

  @media (max-width: 1110px) {
    flex-wrap: wrap;
  }
`
const TextBlock = styled.div`
  margin-right: auto;
  padding-right: 1rem;
  width: 39%;
  @media (max-width: 1110px) {
    width: auto;
  }
`
const Title = styled.div`
  ${sharedStyles.h1}
  margin-top: -.3rem;
  line-height: .98;
  font-weight: bold;
`
const BlocksWrap = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 40.84rem;
  @media (max-width: 1110px) {
    flex-shrink: 1;
    max-width: none;
  }

  @media (max-width: 768px) {
    margin-right: -1.0875rem;
    margin-left: -1.0875rem;
  }
`
const Text = styled.div`
  margin-top: .5rem;
  margin-bottom: 1.5rem;
  color: ${sharedStyles.heroTextColor};
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 33.3%;
  background-size: cover;
  padding-bottom: 1.5rem;
`
const Integrations = styled(Block)`
  background: #1aa0c3 url(${imgIntegrations}) no-repeat 0 0;
`
const Wallets = styled(Block)`
  background: #4daddd url(${imgWallets}) no-repeat 0 0;
`
const Hosting = styled(Block)`
  background: #74bee5 url(${imgHosting}) no-repeat 0 0;
`
const BlockText = styled.div`
  margin-top: auto;
  line-height: 1.3;
  font-weight: bold;
  padding: 0 1.5rem;
  @media (max-width: 400px) {
    padding: 0 1rem;
    font-size: 12px;
  }
`
const BlockIcon = styled.div`
  margin: 2.4rem auto 1.9rem;
  padding: 0 1.5rem;
  max-width: 50%;
  height: 41%;
  max-height: 5.6rem;
`
const IntegrationsBlockIcon = styled(BlockIcon)`
`
const WalletsBlockIcon = styled(BlockIcon)`
`
const HostingBlockIcon = styled(BlockIcon)`
`
const Icon = styled.img`
  display: block;
  height: 100%;
  width: 100%;
`

const Hero = () => (
  <HeroBG>
    <HeroSection>
      <PageWrap>
        <Footer>
          <TextBlock>
            <Title>Blockchain Development and Consulting</Title>
            <Text>Envision the future, get ready today</Text>
          </TextBlock>
          <BlocksWrap>
            <Integrations>
              <IntegrationsBlockIcon>
                <Icon src={iconIntegrations} alt="Blockchain integrations. Custom API, notifications"></Icon>
              </IntegrationsBlockIcon>
              <BlockText>Blockchain integrations. Custom API, notifications</BlockText>
            </Integrations>
            <Wallets>
              <WalletsBlockIcon>
                <Icon src={iconWallets} alt="Custodial wallets solutions plus multisig"></Icon>
              </WalletsBlockIcon>
              <BlockText>Custodial wallets solutions plus multisig</BlockText>
            </Wallets>
            <Hosting>
              <HostingBlockIcon>
                <Icon src={iconHosting} alt="Node hosting, support, deployment, devops"></Icon>
              </HostingBlockIcon>
              <BlockText>Node hosting, support, deployment, devops</BlockText>
            </Hosting>
          </BlocksWrap>
        </Footer>
      </PageWrap>
    </HeroSection>
  </HeroBG>
)

export default Hero
