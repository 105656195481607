import React from "react"
import styled, { css } from 'styled-components'
import sharedStyles from "../shared/styles"
import IconSocialFacebook from './assets/footer/social/facebook.inline.svg'
import IconSocialTwitter from './assets/footer/social/twitter.inline.svg'
import IconSocialTelegram from './assets/footer/social/telegram.inline.svg'
import logoAssociation from './assets/footer/association-logo.png'
import logoBlockvis from './assets/footer/blockvis-logo.svg'

const FooterSection = styled.div`
  background: ${sharedStyles.darkBackgroundColor};
  color: ${sharedStyles.footerColor};
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const Group = styled.div`
  margin: 1rem auto 1rem 0;
  @media (max-width: 1100px) {
    width: 100%;
  }
`
const Nav = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .4rem;
`
const NavLink = styled.a`
  margin-right: 1.7rem;
  font-weight: bold;
  color: ${sharedStyles.footerNavColor};

  &:hover {
    color: ${sharedStyles.footerNavHoverColor};
  }
`
const Copyright = styled.div`
  ${sharedStyles.commonText}
  letter-spacing: 0;
`
const Logo = styled.img`
  margin-right: 1.6rem;
  height: .78rem;
`
const SocialBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 3rem;
`
const SocialLink = styled.a`
  display: flex;
  margin-right: .7rem;
  width: 1.5rem;
  height: 1.5rem;
`
const socialIconStyles = css`
  max-height: 100%;

  &:hover path {
    fill: ${sharedStyles.hoverColor};
  }
`
const IconFacebook = styled(props => <IconSocialFacebook {...props}><use xlinkHref="#svg-facebook-inline"></use></IconSocialFacebook>)`
  ${socialIconStyles};
`
const IconTwitter = styled(props => <IconSocialTwitter {...props}><use xlinkHref="#svg-twitter-inline"></use></IconSocialTwitter>)`
  ${socialIconStyles};
`
const IconTelegram = styled(props => <IconSocialTelegram {...props}><use xlinkHref="#svg-telegram-inline"></use></IconSocialTelegram>)`
  ${socialIconStyles};
`

const Associacion = styled.a`
  ${sharedStyles.commonText}
  display: flex;
  align-items: center;
  line-height: 1.4;
  text-align: right;
  text-decoration: none;
  color: ${sharedStyles.footerColor};

  &:hover {
    color: ${sharedStyles.footerHoverColor};
  }
`
const AssociationLogo = styled.img`
  margin-left: 1rem;
  height: 4.167rem;
  opacity: .6;

  ${Associacion}:hover & {
    opacity: 1;
  }
`

const Footer = () => (
  <FooterSection>
    <PageWrap>
      <Group>
        <Nav>
          <NavLink href="#clients">Clients</NavLink>
          <NavLink href="#testimonials">Testimonials</NavLink>
          <NavLink href="#activities">Activities</NavLink>
          <NavLink href="#services">Services</NavLink>
          <NavLink href="#career">Career</NavLink>
          <NavLink href="#contacts">Contacts</NavLink>
        </Nav>

        <Copyright>
          <Logo src={logoBlockvis} alt="Blockvis" />
          Copyright © 2017 - {new Date().getFullYear()} Blockvis, SIA All Rights Reserved. Privacy policy.
        </Copyright>
      </Group>

      <SocialBlock>
        <SocialLink href="#" aria-label="Link to Facebook">
          <IconFacebook />
        </SocialLink>
        <SocialLink href="#" aria-label="Link to Twitter">
          <IconTwitter />
        </SocialLink>
        <SocialLink href="#" aria-label="Link to Telegram">
          <IconTelegram />
        </SocialLink>
      </SocialBlock>

      <Associacion href="#">
        We are Member of<br/>
        Latvian blockchain association
        <AssociationLogo src={logoAssociation} alt="Latvian blockchain association" />
      </Associacion>
    </PageWrap>
  </FooterSection>
)
export default Footer
