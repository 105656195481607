import React from "react"
import styled from 'styled-components'
import bgImg from './assets/hiring/bg.jpg'
import img1 from './assets/hiring/img1.jpg'
import iconEmail from './assets/hiring/iconEmail.svg'
import iconWeb from './assets/hiring/iconWeb.svg'
import sharedStyles from "../shared/styles"

const HiringSection = styled.div`
  background: #008acf url(${bgImg}) no-repeat 0 0;
  background-size: cover;
  color: ${sharedStyles.lightestColor};
`
const PageWrap = styled.div`
  ${sharedStyles.pageWrap};
  padding-top: 2.5rem;
  padding-bottom: .5rem;
`
const Heading = styled.div`
  ${sharedStyles.h1};
  margin-bottom: 1.4rem;
`
const Group = styled.div`
  display: flex;
  @media (max-width: 720px) {
    display: block;
  }
`
const ImageContainer = styled.div`
  margin-top: .4rem;
  margin-right: 2.2rem;
  flex-shrink: 0;
  flex-basis: 15rem;
  @media (max-width: 480px) {
    display: none;
  }
`
const Image = styled.img`
  margin-bottom: 1rem;
  max-width: 100%;
`
const TextWrap = styled.div`
  display: flex;
  @media (max-width: 980px) {
    display: block;
  }
`
const Text = styled.div`
  margin-right: 3.6rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  @media (max-width: 980px) {
    margin-right: 0;
  }
`
const Sidebar = styled.div`
  margin-bottom: 2rem;
  flex-shrink: 0;
  flex-basis: 16.7rem;
`
const Title = styled.div`
  margin-bottom: .6rem;
  letter-spacing: .06rem;
`
const List = styled.ul`
  margin-bottom: 1.7rem;
`
const Item = styled.li`
  line-height: 1.6;
`
const Footer = styled.div`
`
const FooterLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${sharedStyles.lightestColor};

  &:hover {
    color: ${sharedStyles.lightestHoverColor};
  }
`
const FooterIcon = styled.img`
  margin-right: 1rem;
  width: 1rem;
`

const Hiring = () => (
  <HiringSection id="career">
    <PageWrap>
      <Heading>We are hiring</Heading>
      <Group>
        <ImageContainer>
          <Image src={img1} alt="We are hiring" />
        </ImageContainer>
        <TextWrap>
          <Text data-aos="fade-up" data-aos-delay="100">
            Our company always demonstrated strong problem-solving skills for our clients.
            <br/>
            This is the skill we appreciate the most. As a new team member with no excuse, you will be immediately put into the challenging context: scalability, security, privacy-preserving, compliance and other aspects of modern crypto-related projects. Maybe not all at once - because we are correctly distributing all the work between relevant engineers.
            <br/>
            <br/>
            We are looking for solid professionals with a provable background, maybe not exactly in crypto or blockchain, as we can quickly turn any professional with relevant developer skills into a crypto-expert (it's our teaching know-how).
          </Text>
          <Sidebar data-aos="fade-up" data-aos-delay="200">
            <Title>In return, we are offering:</Title>
            <List>
              <Item>the stable competitive salary for professionals bringing great projects into life</Item>
              <Item>ability to learn emerging technologies at first hand</Item>
              <Item>friendly environment</Item>
              <Item>achievement bonuses</Item>
            </List>
            <Footer>
              <FooterLink href="mailto:jobs@blockvis.com"><FooterIcon src={iconEmail} alt="" /> jobs@blockvis.com</FooterLink>
              <FooterLink target={'_blank'} href="https://cv.lv/search/employer/blockvis?employerId=30628&employerName=Blockvis"><FooterIcon src={iconWeb} alt="" /> View our vacancies</FooterLink>
            </Footer>
          </Sidebar>
        </TextWrap>
      </Group>
    </PageWrap>
  </HiringSection>
)
export default Hiring
