import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import sharedStyles from "../shared/styles"
import logoSvg from "./assets/blockvis-logo.svg"
import barsSolidSvg from "./assets/bars-solid.svg"
import styled from 'styled-components'

const HeaderSection = styled.div`
`
const PageWrap = styled.header`
  ${sharedStyles.pageWrap};
  display: flex;
  align-items: center;
  min-height: 100px;
  @media (max-width: 950px) {
    min-height: 60px;
  }
`
const Logo = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: auto;
  width: 9.9rem;
  text-decoration: none;
`;
const LogoImg = styled.img`
  max-width: 100%;
`
const MobileToggle = styled(props => <button {...props} type="button"></button>)`
  display: none;
  @media (max-width: 950px) {
    display: block;
    width: 21px;
    height: 24px;
    border: none;
    padding: 0;
    background: url(${barsSolidSvg}) no-repeat 0 0;
    cursor: pointer;
  }
`
const Drawer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 950px) {
    z-index: 1;
    position: absolute;
    top: 60px;
    right: 0;
    bottom: 0;
    display: none;
    overflow: auto;
    background: ${sharedStyles.lightestColor};
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .5);

    &.opened {
      display: block;
    }
  }
`
const MenuSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 950px) {
    padding: 0 20px;
    flex-direction: column;
  }
`
const MenuSectionItem = styled.a`
  margin: 0 1.2rem;
  font-weight: bold;
  color: ${sharedStyles.darkestColor};

  &:hover {
    color: ${sharedStyles.darkestHoverColor};
  }
  @media (max-width: 950px) {
    margin-bottom: 1rem;
  }
`
// const Languages = styled.div`
//   position: relative;
//   margin-left: 1.2rem;
//   text-align: center;
//   border-radius: 3px;
//   background: ${sharedStyles.languagesBackgroundColor};
//   color: ${sharedStyles.textColor};

//   @media (max-width: 950px) {
//     margin-left: 0;
//   }
// `
// const LanguagesTitle = styled.div`
//   position: relative;
//   padding: .2rem 1.2rem;
//   cursor: pointer;

//   &::after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     right: .3rem;
//     margin-top: -2px;
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-width: 7px 5px 0 5px;
//     border-color: ${sharedStyles.languagesArrowBackgroundColor} transparent transparent transparent;
//   }
// `
// const LanguagesMenu = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   display: none;
//   padding-bottom: .2rem;
//   border-radius: 0 0 3px 3px;
//   background: ${sharedStyles.languagesBackgroundColor};

//   ${Languages}:hover & {
//     display: block;
//   }
// `
// const LanguagesMenuItem = styled.a`
//   display: block;
//   text-decoration: none;
//   color: ${sharedStyles.textColor};
// `

const Header = ({ siteTitle }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <HeaderSection>
      <PageWrap>
        <Logo to="/">
          <LogoImg src={logoSvg} alt={siteTitle}></LogoImg>
        </Logo>

        <MobileToggle onClick={() => setToggle(!toggle)} />
        <Drawer className={toggle ? 'opened' : ''}>
          <MenuSection>
            <MenuSectionItem href="#clients">Clients</MenuSectionItem>
            <MenuSectionItem href="#testimonials">Testimonials</MenuSectionItem>
            <MenuSectionItem href="#activities">Activities</MenuSectionItem>
            <MenuSectionItem href="#services">Services</MenuSectionItem>
            <MenuSectionItem href="#career">Career</MenuSectionItem>
            <MenuSectionItem href="#contacts">Contacts</MenuSectionItem>
          </MenuSection>

          {/* <Languages>
            <LanguagesTitle>english</LanguagesTitle>
            <LanguagesMenu>
              <LanguagesMenuItem href="#">german</LanguagesMenuItem>
              <LanguagesMenuItem href="#">spanish</LanguagesMenuItem>
            </LanguagesMenu>
          </Languages> */}
        </Drawer>
      </PageWrap>
    </HeaderSection>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
